import * as React from 'react';

function SvgIconCompliancePlanComplete(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none" {...props}>
      <path
        fill="#23AC9C"
        d="m24.844 8.127-6.188-3.442a5.487 5.487 0 0 0-5.312 0L7.156 8.128C5.512 9.041 4.5 10.73 4.5 12.558v6.884c0 1.828 1.012 3.517 2.656 4.43l6.188 3.442a5.487 5.487 0 0 0 5.312 0l6.188-3.441c1.644-.914 2.656-2.603 2.656-4.431v-6.884c0-1.828-1.012-3.517-2.656-4.43Z"
      />
      <path
        fill="#fff"
        d="M20.44 12.222a.688.688 0 0 1 .982 0 .708.708 0 0 1 .009.984l-5.544 6.553a.688.688 0 0 1-.998.02l-3.373-3.42a.71.71 0 0 1 .225-1.145.687.687 0 0 1 .757.152l2.86 2.9 5.063-6.024a.243.243 0 0 1 .019-.02Z"
      />
    </svg>
  );
}

export default SvgIconCompliancePlanComplete;
