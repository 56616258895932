import * as React from 'react';

function SvgIconComplianceElementIncomplete(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} viewBox="0 0 32 32" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.3062 6.37685L19.0551 1.78793C17.1661 0.737357 14.834 0.737356 12.945 1.78793L4.69381 6.37685C2.80849 7.42538 1.66669 9.34954 1.66669 11.4111V20.5889C1.66669 22.6505 2.80849 24.5746 4.69381 25.6231L12.945 30.2121C14.834 31.2626 17.1661 31.2626 19.0551 30.2121L27.3062 25.6231C29.1915 24.5746 30.3333 22.6505 30.3333 20.5889V11.4111C30.3333 9.34955 29.1915 7.42538 27.3062 6.37685ZM19.5411 0.913996L27.7923 5.50292C29.9835 6.72158 31.3333 8.97376 31.3333 11.4111V20.5889C31.3333 23.0262 29.9835 25.2784 27.7923 26.4971L19.5411 31.086C17.3499 32.3047 14.6502 32.3047 12.4589 31.086L4.20777 26.4971C2.01654 25.2784 0.666687 23.0262 0.666687 20.5889V11.4111C0.666687 8.97376 2.01654 6.72158 4.20777 5.50292L12.4589 0.913996C14.6502 -0.304665 17.3499 -0.304665 19.5411 0.913996Z"
        fill="#69707D"
      />
    </svg>
  );
}

export default SvgIconComplianceElementIncomplete;
