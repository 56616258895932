import * as React from 'react';

function SvgIconComplianceElementComplete(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} viewBox="0 0 32 32" fill="none" {...props}>
      <path
        d="M27.7923 5.50292L19.5411 0.913996C17.3499 -0.304665 14.6502 -0.304665 12.4589 0.913995L4.20777 5.50292C2.01654 6.72158 0.666687 8.97376 0.666687 11.4111V20.5889C0.666687 23.0262 2.01654 25.2784 4.20777 26.4971L12.4589 31.086C14.6502 32.3047 17.3499 32.3047 19.5411 31.086L27.7923 26.4971C29.9835 25.2784 31.3333 23.0262 31.3333 20.5889V11.4111C31.3333 8.97376 29.9835 6.72158 27.7923 5.50292Z"
        fill="#23AC9C"
      />
      <path
        d="M21.92 10.9624C22.0053 10.8754 22.1071 10.8062 22.2195 10.759C22.3318 10.7118 22.4525 10.6875 22.5744 10.6875C22.6962 10.6875 22.8169 10.7118 22.9292 10.759C23.0416 10.8062 23.1434 10.8754 23.2287 10.9624C23.5862 11.3237 23.5912 11.9074 23.2412 12.2749L15.85 21.0124C15.7661 21.1046 15.6642 21.1786 15.5507 21.2301C15.4372 21.2815 15.3143 21.3093 15.1897 21.3116C15.0651 21.3139 14.9413 21.2908 14.826 21.2437C14.7106 21.1965 14.606 21.1264 14.5187 21.0374L10.0212 16.4799C9.84777 16.303 9.75061 16.0652 9.75061 15.8174C9.75061 15.5697 9.84777 15.3318 10.0212 15.1549C10.1065 15.0679 10.2083 14.9987 10.3207 14.9515C10.4331 14.9043 10.5537 14.88 10.6756 14.88C10.7975 14.88 10.9181 14.9043 11.0305 14.9515C11.1429 14.9987 11.2447 15.0679 11.33 15.1549L15.145 19.0212L21.895 10.9899C21.9027 10.9803 21.9111 10.9711 21.92 10.9624Z"
        fill="white"
      />
    </svg>
  );
}

export default SvgIconComplianceElementComplete;
