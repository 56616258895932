import * as React from 'react';

function SvgIconCompliancePlanPartial(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none" {...props}>
      <path
        fill="#23AC9C"
        fillRule="evenodd"
        d="M24.358 9.001 18.17 5.56a4.488 4.488 0 0 0-4.34 0L7.642 9.001C6.304 9.745 5.5 11.106 5.5 12.558v6.884c0 1.452.804 2.813 2.142 3.557l6.188 3.442a4.488 4.488 0 0 0 4.34 0l6.188-3.442c1.338-.744 2.142-2.105 2.142-3.557v-6.884c0-1.452-.804-2.813-2.142-3.557Zm-5.702-4.315 6.188 3.441c1.644.914 2.656 2.603 2.656 4.431v6.884c0 1.828-1.012 3.517-2.656 4.43l-6.188 3.442a5.487 5.487 0 0 1-5.312 0l-6.188-3.441C5.512 22.959 4.5 21.27 4.5 19.442v-6.884c0-1.828 1.012-3.517 2.656-4.43l6.188-3.442a5.487 5.487 0 0 1 5.312 0Z"
        clipRule="evenodd"
      />
      <path
        fill="#23AC9C"
        d="m13.344 27.314-6.188-3.441C5.512 22.959 4.5 21.27 4.5 19.442v-6.884c0-1.828 1.012-3.517 2.656-4.43l6.188-3.442A5.465 5.465 0 0 1 16 4v24a5.465 5.465 0 0 1-2.656-.686Z"
      />
    </svg>
  );
}

export default SvgIconCompliancePlanPartial;
