import * as React from 'react';

const IconCompliancePlanIncomplete = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m17.41 7.625-4.125-2.294a2.658 2.658 0 0 0-2.57 0L6.59 7.625c-.79.44-1.256 1.238-1.256 2.08v4.59c0 .842.466 1.64 1.256 2.08l4.126 2.294a2.659 2.659 0 0 0 2.569 0l4.125-2.294c.79-.44 1.257-1.238 1.257-2.08v-4.59c0-.842-.467-1.64-1.257-2.08Zm-3.64-3.168 4.126 2.294c1.096.61 1.77 1.736 1.77 2.955v4.588c0 1.22-.674 2.345-1.77 2.954l-4.125 2.295a3.658 3.658 0 0 1-3.541 0l-4.126-2.294c-1.096-.61-1.77-1.736-1.77-2.954v-4.59c0-1.218.674-2.344 1.77-2.954l4.126-2.294a3.658 3.658 0 0 1 3.54 0Z"
      fill="#69707D"
    />
  </svg>
);

export default IconCompliancePlanIncomplete;
