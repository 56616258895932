import * as React from 'react';
import { SVGProps } from 'react';

function IconA11YHealthAdvisor(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 3 3 6.6c.008 8.136 2.034 10.554 9 14.4 7.023-3.862 8.924-6.35 9-14.4L12 3Z"
        stroke="#7119E1"
        strokeWidth={1.5}
        strokeLinejoin="round"
      />
      <path d="M12.94 6.94a.94.94 0 1 1-1.88 0 .94.94 0 0 1 1.88 0Z" fill="#7119E1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.018 8.357a.616.616 0 0 1 .746-.45c1.672.412 2.934.609 4.194.608 1.261 0 2.552-.199 4.284-.61a.616.616 0 1 1 .284 1.2c-1.776.42-3.17.641-4.567.642-1.398 0-2.767-.22-4.49-.644a.616.616 0 0 1-.45-.746Z"
        fill="#7119E1"
      />
      <path d="M10.748 12.263V9.13h2.505v3.132h-2.505Z" fill="#7119E1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.45 10.399c.338.042.577.35.535.688l-.626 5.01a.616.616 0 0 1-1.223-.153l.626-5.01a.616.616 0 0 1 .688-.535Z"
        fill="#7119E1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.55 10.399a.616.616 0 0 1 .688.535l.626 5.01a.616.616 0 1 1-1.223.153l-.626-5.01a.616.616 0 0 1 .535-.688Z"
        fill="#7119E1"
      />
    </svg>
  );
}
export default IconA11YHealthAdvisor;
