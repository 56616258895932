import { RangeInput as GrRangeInput } from 'grommet';
import React from 'react';

import { FormField } from '../FormField/FormField';

export interface RangeInputProps {
  /**
   * Name of the input.
   */
  name: string;
  /**
   * Label for the input
   */
  label: React.ReactNode;
  /**
   * value for the input
   */
  value?: number;
  /**
   * Optional range upper bound.
   * Defaults to 100
   */
  min?: number;
  /**
   * Optional range lower bound.
   * Defaults to 0
   */
  max?: number;
  /**
   * The amount by which the range increments/decrements value.
   * Defaults to 1
   */
  step?: number;
  /**
   * Optional change handler
   */
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

export const RangeInput = React.forwardRef<HTMLInputElement, RangeInputProps>(
  ({ name, label, min, max, step, onChange, value }: RangeInputProps, ref) => (
    <FormField name={name} label={label}>
      <GrRangeInput min={min} max={max} step={step} onChange={onChange} value={value} name={name} ref={ref} />
    </FormField>
  ),
);
