import * as React from 'react';

function SvgIconCompliancePlanPast(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
      <path
        fill="#69707D"
        d="m17.563 6.751-4.126-2.294a3.658 3.658 0 0 0-3.54 0L5.77 6.751C4.675 7.361 4 8.487 4 9.706v4.588c0 1.22.675 2.345 1.77 2.954l4.126 2.295c1.096.61 2.446.61 3.541 0l4.126-2.294c1.095-.61 1.77-1.736 1.77-2.954v-4.59c0-1.218-.675-2.344-1.77-2.954Z"
      />
      <path
        fill="#fff"
        d="M14.627 9.481a.457.457 0 0 1 .654 0c.179.18.181.473.006.656l-3.695 4.37a.458.458 0 0 1-.666.012L8.677 12.24a.473.473 0 0 1 .15-.764.458.458 0 0 1 .505.101l1.907 1.934 3.375-4.016a.148.148 0 0 1 .013-.014Z"
      />
    </svg>
  );
}

export default SvgIconCompliancePlanPast;
